<template>
  <section>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(changePassword)">
        <b-form-group id="changePassword">
          <div class="row">
            <div class="col-12 mb-3">
              <h4>
                <b>Adgangskode</b>
              </h4>
            </div>
            <div class="col-lg-6 col-12">
              <validation-provider
                :name="$t('signup.password')"
                :rules="{ required: true, min: 6 }"
                v-slot="validationContext"
                vid="confirm"
              >
                <b-form-input
                  class="input"
                  id="signupPassword"
                  name="signupPassword"
                  type="password"
                  :placeholder="$t('signup.password')"
                  v-model="password"
                  :state="getValidationState(validationContext)"
                  aria-describedby="password-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="password-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-lg-6 col-12">
              <validation-provider
                :name="'Gentag adgangskode'"
                rules="required|password:@confirm"
                v-slot="validationContext"
              >
                <b-form-input
                  class="input"
                  id="signupPasswordtwice"
                  name="signupPasswordtwice"
                  data-cy="signupPasswordtwice"
                  type="password"
                  :placeholder="'Gentag adgangskode'"
                  v-model="password2"
                  :state="getValidationState(validationContext)"
                  aria-describedby="password-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="password-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-12">
              <BaseActionButton
                v-bind:clicked="clicked"
                v-bind:disabled="clicked"
                type="submit"
                id="signupButton"
                class="mr-3"
              >
                Ændr adgangskode
              </BaseActionButton>
            </div>
          </div>
        </b-form-group>
      </b-form>
    </validation-observer>
  </section>
</template>
<script>
export default {
  name: "ChangePassword",
  data() {
    return {
      clicked: false,
      password: null,
      password2: null,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async changePassword() {
      try {
        this.clicked = true;
        await this.$store.dispatch("userVuex/changePassword", {
          id: this.userUid,
          newPassword: this.password,
        });
        this.clicked = false;
        this.toast("Success", "Brugerens password er nu ændret!", true);
        this.clicked = false;
        if (this.isMe) {
          console.log("Logging out");
          this.toast(
            "Log ud",
            "Du vil blive logget ud, nu kan du logge dig på igen med den nye password", true
          );
          await this.$store.dispatch("auth/signOutAction");
          this.$router.push({
            name: "Login",
          });
        }
      } catch (err) {
        this.clicked = false;
        console.log(err);
        this.toast("Fejl", err, false);
      }
    },
  },
  computed: {
    userUid() {
      return this.$route.params.id;
    },
    isMe() {
      return this.userUid == this.myUid;
    },
    myUid() {
      return this.$store.getters["auth/uid"];
    },
  },
};
</script>

<style lang="scss">
@import "./src/styles/global.scss";
</style>