<template>
  <section>
    <div class="row">
      <div class="col-12 mb-3">
        <h4>
          <b>Email</b>
        </h4>
      </div>
      <div class="col-lg-6 col-12">
        <b-form-input
          class="input"
          type="email"
          disabled
          :placeholder="$t('global.user.email')"
          v-model="userEmail"
        >
        </b-form-input>
        <BaseActionButton
          v-if="!show"
          @click.native="show = true"
          type="button"
          id="signupButton"
          class="mr-3 btn-small-red"
        >
          Ændr email
        </BaseActionButton>
      </div>
    </div>
    <validation-observer>
      <b-form @submit.prevent="handleSubmit()">
        <b-form-group id="changeEmail">
          <div class="row" v-if="show">
            <div class="col-lg-6 col-12">
              <validation-provider
                :name="$t('global.user.email')"
                :rules="{ required: true, email }"
                v-slot="validationContext"
                vid="email"
              >
                <b-form-input
                  :disabled="clicked"
                  class="input"
                  id="email"
                  :name="$t('global.user.email')"
                  type="email"
                  :placeholder="'Ny email'"
                  v-model="email"
                  :state="getValidationState(validationContext)"
                  aria-describedby="email-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="signup-emailForm-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-lg-6 col-12">
              <validation-provider
                :name="'Bekræft email'"
                rules="required|confirmed:email"
                v-slot="validationContext"
              >
                <b-form-input
                  :disabled="clicked"
                  class="input"
                  id="emailTwice"
                  :name="'Bekræft email'"
                  type="email"
                  :placeholder="'Bekræft email'"
                  v-model="email2"
                  :state="getValidationState(validationContext)"
                  aria-describedby="emailTwice-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="emailTwice-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-12">
              <BaseActionButton
                v-bind:clicked="clicked"
                :disabled="clicked"
                type="submit"
                id="signupButton"
                class="mr-3"
                v-bind:class="{
                  'btn-small-red': !$route.meta.admin,
                  'btn-admin-green': $route.meta.admin,
                }"
              >
                Gem ny email addresse
              </BaseActionButton>
            </div>
          </div>
        </b-form-group>
      </b-form>
    </validation-observer>
  </section>
</template>
<script>
export default {
  name: "ChangeEmail",
  data() {
    return {
      clicked: false,
      email: null,
      email2: null,
      show: false,
    };
  },
  mounted() {},
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async handleSubmit() {
      try {
        this.clicked = true;
        await this.$store.dispatch("userVuex/changeEmail", {
          id: this.id,
          newEmail: this.email,
        });
        this.toast(
          "Success",
          "Brugerens email er nu opdateret", true
        );
        this.clicked = false;
        if (this.isMe) {
          this.toast(
            "Log ud",
            "Du vil blive logget ud, nu kan du logge dig på igen med den nye email-addresse", true
          );
          await this.$store.dispatch("auth/signOutAction");
          this.$router.push({
            name: "Login",
          });
        }
      } catch (err) {
        this.clicked = false;
        this.toast("Fejl", err, false);
      }
    },
  },
  computed: {
    userEmail() {
      var email = this.$store.getters["userVuex/userAuth"]?.email ?? null;
      return email;
    },
    id() {
      return this.$route.params.id;
    },
    isMe() {
      return this.id == this.myUid;
    },
    myUid() {
      return this.$store.getters["auth/uid"];
    },
  },
};
</script>

<style lang="scss">
@import "./src/styles/global.scss";
</style>