<template>
  <section >
    <b-form @submit.prevent="createAuth">
      <div class="row">
        <div class="col-12 mb-3">
          <h4>
            <b>Opret login-konto til brugeren</b>
          </h4>
          <i>
            Brugerens konto er ikke tilknyttet endnu, derfor skal der oprettes
            en konto før brugeren kan logge sig på.
          </i>
        </div>

        <div class="col-lg-6 col-12">
          <label>
            {{ $t("global.user.email") }}
          </label>
          <InputText v-model="email" :type="'email'" :required="true" />
        </div>
        
        <div class="col-lg-6 col-12">
          <BaseActionButton
            v-bind:clicked="clicked"
            v-bind:disabled="clicked"
            type="submit"
            id="signupButton"
            class="mr-3 btn-small-red"
          >
            Opret konto
          </BaseActionButton>
        </div>
      </div>
    </b-form>
  </section>
</template>
<script>
import InputText from "@/modules/global/atomComponents/InputText.vue";

export default {
  name: "ChangeEmail",
  components: {
    InputText,
  },
  data() {
    return {
      clicked: false,
      email: "",
      inputOptions: {
        required: true,
      },
    };
  },
  mounted() {
    var userEmail = this.$store.getters["userVuex/user"]?.email
    this.email = userEmail
    
  },
  methods: {
    async createAuth() {
      try {
        this.clicked = true;
        await this.$store.dispatch("userVuex/createAuth", {
          id: this.id,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
        });
        this.toast(
          "Success",
          "Brugerens login konto er nu oprettet og tilknyttet - Brugeren kan nu anmode om at nulstille sin adgangskode, så brugeren kan indtaste sin nye adgangskode.", true
        );
        this.clicked = false;
      } catch (err) {
        this.clicked = false;
        console.log(err);
        this.toast("Fejl", err, false);
      }
    },
  },
  computed: {
    userEmail() {
      var email = this.$store.getters["userVuex/userAuth"]?.email ?? null;
      return email;
    },
    firstName() {
      return this.user.firstName;
    },
    lastName() {
      return this.user.lastName;
    },
    user() {
      return this.$store.getters["userVuex/user"];
    },
    id() {
      return this.$route.params.id;
    },
    isMe() {
      return this.id == this.myUid;
    },
    myUid() {
      return this.$store.getters["auth/uid"];
    },
  },
};
</script>

<style lang="scss">
@import "./src/styles/global.scss";
</style>